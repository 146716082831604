<template>
  <div :class="cn('aspect-square', props.class)" role="img">
    <div
      :class="cn('tab-circle aspect-square rounded-full transition-all duration-500', classes?.root)"
      :style="{ ...ProductStyles({ product: productId, outline, size, shadow }), padding: undefined }"
    >
      <div
        class="flex h-full w-full items-center justify-center rounded-full transition-all duration-500"
        :style="{
          border: '1px solid rgba(0, 0, 0, 0.06)',
          'box-shadow': '-2px -3px 5px 0px rgba(0,0,0,0.10) inset',
          ...ProductStyles({ size }),
        }"
      >
        <QLogo />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type StyleVariantProps, sva } from "style-variance-authority"

import { PricingProductEnum, UiPricingPlans } from "@finq/pricing/types"

const props = withDefaults(
  defineProps<{
    class?: ClassValue
    productId: PricingProductEnum
    classes?: Partial<{ root: ClassValue }>
    outline?: StyleVariantProps<typeof ProductStyles>["outline"]
    size?: StyleVariantProps<typeof ProductStyles>["size"]
    shadow?: StyleVariantProps<typeof ProductStyles>["shadow"]
  }>(),
  {}
)

const ProductStyles = sva({
  defaultVariants: {
    size: "medium",
  },

  variants: {
    product: {
      [PricingProductEnum.Stocks]: {
        background: UiPricingPlans.COLORS[PricingProductEnum.Stocks].gradient,
        filter: UiPricingPlans.COLORS[PricingProductEnum.Stocks].filter,
        outline: UiPricingPlans.COLORS[PricingProductEnum.Stocks].outline,
      },
      [PricingProductEnum.Funds]: {
        background: UiPricingPlans.COLORS[PricingProductEnum.Funds].gradient,
        filter: UiPricingPlans.COLORS[PricingProductEnum.Funds].filter,
        outline: UiPricingPlans.COLORS[PricingProductEnum.Funds].outline,
      },
      [PricingProductEnum.Pensions]: {
        background: UiPricingPlans.COLORS[PricingProductEnum.Pensions].gradient,
        filter: UiPricingPlans.COLORS[PricingProductEnum.Pensions].filter,
        outline: UiPricingPlans.COLORS[PricingProductEnum.Pensions].outline,
      },
    },

    outline: {
      white: {
        outline: "3px solid rgba(251, 251, 251, 0.80)",
      },
      none: {
        outline: undefined,
      },
    },

    shadow: {
      none: {
        filter: undefined,
      },
    },

    size: {
      small: {
        width: "28px",
        height: "28px",
        padding: "6px",
        outlineWidth: "1px",
      },
      medium: {
        width: "48px",
        height: "48px",
        padding: "10px",
      },
      large: {
        width: "64px",
        height: "64px",
        padding: "12px",
      },
    },
  },
})
</script>

<style lang="scss">
.chip-gradient-properties {
  @property --base-color {
    syntax: "<color>";
    initial-value: #34b4f4;
    inherits: false;
  }

  @property --base-color-light {
    syntax: "<color>";
    initial-value: #3d99f5;
    inherits: false;
  }
}
</style>
